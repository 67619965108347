<script>
import List from './list'
import common from '@/modules/clueDistribution/common.vue'
import wxsdk from '@/scripts/wxsdk'
import clueServices from '@/services/clueServices'
import loading from '@/utils/loading'
import vendorServices from '@/services/vendorServices'
import { getWxEntry } from '@/utils'
export default {
  name:'ClueHome',
  components:{ List,common },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.contextEntry) {
        const ua = window.navigator.userAgent
        if (ua.includes('wxwork')) {
          loading.showLoading()
          vendorServices.wxAgentConfig(
            async () => {
              try {
                vm.contextEntry = await getWxEntry()
                if (['single_chat_tools', 'group_chat_tools'].includes(vm.contextEntry)) {
                  vm.getCurExternalId()
                } else {
                  vm.init()
                }
              } catch (error) {
                vm.contextEntry = 'normal'
                vm.init()
                vm.$toast(error.message || error)
              }
              loading.hideLoading()
            },
            (error) => {
              vm.contextEntry = 'normal'
              vm.init()
              vm.$toast(error.message || error)
            }
          )
        } else {
          vm.contextEntry = 'normal'
          vm.init()
        }
      }
      vm.$store.commit('app/addKeepAlive', 'ClueHome')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/delivery-assistant','/salesAssistant','/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.$refs.componentName.filterPanelVisible = false
      this.$refs.componentName.filterParams = {}
      this.$refs.componentName.$refs.filterPanel.reset()
      this.$store.commit('app/deleteKeepAlive', 'ClueHome')
    }
    if (to.name == 'salesAssistantPortalHome') {
      this.$store.commit('app/deleteKeepAlive', 'ClueHome')
    }
    next()
  },
  data() {
    return {
      show: false,
      type:0,
      tabs:[
        {
          name: this.$t('线索管理'),
          value: 0,
          componentName:'List'
        },
        {
          name:this.$t('公共线索'),
          value: 1,
          componentName:'common'
        },
      ],
      outsideTabs:[],
      leadStageCode: 'LEAD',
      noticeTime: '',
      userId: '',
      contextEntry: ''
      // {
      //   LEAD: '线索',
      //   OPPORTUNITY: '商机',
      //   REDUCE: '线索降级提醒',
      //   DEFEAT:' 战败预警提醒'
      // }
    }
  },
  computed:{
    outsideTab() {
      return this.outsideTabs.slice(1)
    }
  },
  mounted() {
    console.log(111111111111, window.wx)
  },
  activated() {
    if (this.type === 1){
      this.$refs.componentName[0].onRefresh(true)
      this.$refs.componentName[0].removeCheck()
    }
  },
  methods:{
    onTabChange() {
      this.outsideTabs = []
    },
    init() {
      const { tabs, noticeType='', time } = this.$route.query
      if (tabs){
        this.outsideTabs = tabs.split(',')
      }
      if (noticeType) {
        switch (noticeType){
          case 'reduce':
            this.leadStageCode = 'REDUCE'
            break
          case 'defeat':
            this.leadStageCode = 'DEFEAT'
            break
          default:
            this.leadStageCode = 'LEAD'
            break
        }
      }
      this.noticeTime = time
      this.type = Number(this.outsideTabs[0] || 0)
    },
    async getCurExternalId() {
      let params = {}
      if (this.contextEntry == 'single_chat_tools') {
        this.userId = await wxsdk.getCurExternalContact()
        params = { userWeChat: this.userId, chatGroupId: null } 
      } else if (this.contextEntry == 'group_chat_tools') {
        this.chatId = await wxsdk.getCurExternalChat()
        params = { chatGroupId: this.chatId, userWeChat: null } 
      }
      const data = await clueServices.hatchDetailByWechat(params)
      if (data && data.code === 0 && data.data){
        this.goDetail({ id: data.data.id }, true)
      } else {
        this.show = true
        data.code !== 0 && this.$toast(data.msg)
      }
    },
    async judgeEntry() {
      if (!this.userId) {
        // 获取外部联系人id
        this.userId = await wxsdk.getCurExternalContact()
        const data = await clueServices.hatchDetailByWechat({
          userWeChat: this.userId,
        })
        if (data && data.code === 0 && data.data){
          this.goDetail({ id: data.data.id })
        } else {
          this.init()
        }
      }

    },
    // 跳转至详情
    goDetail({ id }, replace = false) {
      this.$router.push({
        path: '/clue-details',
        query: {
          id,
          contextEntry: this.contextEntry,
          replace
        },
      })
    }
  }
}
</script>
<template>
  <!-- 屏蔽公共线索部分 -->
<!-- <van-tabs v-model="type" class="tabs1" @change="onTabChange">
  <van-tab
    v-for="item in tabs"
    :key="item.value"
    :title="item.name"
    :name="item.value"
  >
  <component :is="item.componentName" v-if="item.value === type" ref="componentName" :outsideTab="outsideTab" :isClue="true" />
  </van-tab>
</van-tabs> -->
<div>
  <component :is="'List'" v-if="contextEntry && !['single_chat_tools', 'group_chat_tools'].includes(contextEntry)" ref="componentName" :leadStageCode="leadStageCode" :noticeTime="noticeTime"/>
  <van-overlay :show="show" class-name="no-data-overlay">
    <Empty description="无客户线索信息" />
  </van-overlay>
</div>
</template>
<style scoped>
.no-data-overlay {
  background: white;
}
</style>
