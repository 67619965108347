import ClueDistributionServices from '@/services/clueDistributionServices'
export default {
  data() {
    const that = this
    return {
      showDistribution: false,
      distributionOptions: [
        {
          name: '',
          type: 'B_PEOPLE_SELECTION',
          field: 'saIdList',
          isSelection: true,
          valueType: 'object',
          shopIds: '',
          height: '80vh',
          get roleCodes() {
            return that.getRoleCodes()
          },
          filterData(data) {
            return that.getFilterData(data)
          }
        },
      ],
      defaultActiveKey: ['saIdList']
    }
  },
  methods: {
    setAssignRecycle(params) {
      return ClueDistributionServices.setAssignRecycle(params)
    },
    handleDistribution(params) {
      return ClueDistributionServices.handleDistribution(params)
    }
  }
}
