import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import baseDataServices from '@/services/baseDataServices'
import formatParams from '@/utils/formatParams'
import clueMixin from '@/modules/clue/list/mixin'
export default {
  data() {
    return {
      filterPanelVisible: false,
      filterOptions: [],
    }
  },
  mixins: [clueMixin],
  computed: {
    ...mapGetters([
      'dictHash',
      'userInfo'
    ]),
  },
  watch: {
    dictHash: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.isClue) {
            this.createClueFilterOptions()
          } else {
            this.createFilterOptions()
          }
        }
      },
    }
  },
  methods: {
    onFilter(params) {
      this.filterParams = formatParams(params, false)
      if (this.filterParams.distributeTimeEnd && this.filterParams.distributeTimeStart){
        this.$set(this.filterParams,'distributeTime',{
          start: this.filterParams.distributeTimeStart,
          end: this.filterParams.distributeTimeEnd,
        })
      }
      this.filterPanelVisible = false
      this.onRefresh()
    },
    // 搜索面板条件
    async createFilterOptions() {
      const ret = []
      const dictHash = this.$store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        label: this.$t('下发时间'),
        dateType: 'datetime',
        start: {
          field: 'distributeTimeStart',
        },
        end: {
          field: 'distributeTimeEnd',
        },
        timeOptions: [
          { value: 0, key: 'day', text: '今天' },
          { value: 1, key: 'hour', text: '1小时内' },
        ]
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('线索归属'),
        field: 'sourceType',
        multiple: true,
        options: getOptionsByDictType(1007),
      })
      // 渠道，新版
      ret.push({
        id: uuidv4(),
        type: 'channels',
        label: this.$t('来源渠道'),
        field: 'channels',
        tagsInput: true,
        fields: ['channelOneId', 'channelTwoId', 'channelThreeId', 'channelFourId', 'channelFiveId'],
      })
      // const seriesList = await baseDataServices.getAllSeriesModels()
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: this.$t('意向车型'),
      //   field: 'seriesCodes',
      //   multiple: true,
      //   options: seriesList.map(({ code, name, models }) => ({ id: code, label: name, value: code, modelList: models })),
      // })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '',
      //   field: 'modelCodes',
      //   multiple: true,
      //   options: [],
      // })
      // 车系车型，新版
      ret.push({
        id: uuidv4(),
        type: 'cars',
        label: this.$t('意向车型'),
        field: 'cars',
        tagsInput: true,
        fields: ['seriesCodes', 'modelCodes'],
      })
      this.filterOptions = ret
      console.log('ssss:', this.filterOptions)
    },
  }
}
