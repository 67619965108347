<script>
export default {
  props:{
    type: {
      type: [String,Number],
      default:0
    },
    tags:{
      type: Array,
      default:()=>([])
    }
  },
  methods:{
    onTabChange(item){
      this.$emit('change',item.value,item)
    }
  }
}
</script>
<template>
  <div class="tabs">
        <template v-for="(item,index) in tags">
          <template v-if="item.value === 'TO_BE_ASSIGN'">
            <div v-if="item.premission"  :key="item.value" v-btn-premission="item.premission" :class="{current: item.value === type}" class="tab" @click="onTabChange(item)">{{ item.name + (item.value ? `(${item.num || 0})` : '') }}</div>
          </template>
          <div v-else  :key="item.value" :class="{current: item.value === type}" class="tab" @click="onTabChange(item)">{{ item.name + (item.value ? `(${item.num || 0})` : '') }}</div>
        </template>
    </div>
</template>
<style lang="less" scoped>
.tabs{
  font-size: 13px;
  display: flex;
  overflow-x: auto;
  padding: 8px 16px; 
  .tab{
    flex: 1 0 auto;
    padding: 4px 12px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.08);
    cursor: pointer;
    &:not(:last-child){
      margin-right: 12px;
    }
    &.current{
      background-color: #EED484;
      color: #B9921A;
    }
  }
}
</style>
